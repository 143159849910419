import { Button, CssTooltip, INotificationContext, InputField } from '@livechat/design-system';
import { useEffect, useState } from 'react';
import { SimpleCard } from './card/SimpleCard';
import styled from 'styled-components';
import { useClient } from '../hooks/useClient';
import { Configuration } from '../client/client';
import axios from 'axios';

const DocumentationUrl = styled.a`
  margin-left: 0.25rem;
  text-decoration: none;

  &:visited,
  &:hover {
    text-decoration: none;
    color: #4384f5;
  }
`;

const TooltipTarget = styled.span`
  position: relative;
  display: inline-block;
`;

const Circle = styled.span`
  margin-left: 2px;
  border: 1px solid rgba(66, 77, 87, 0.5);
  color: rgba(66, 77, 87, 0.5);
  border-radius: 8px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ApiKeyCard = ({ notificationSystem }: { notificationSystem: INotificationContext }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>('');

  const client = useClient();

  const [configuration, setConfiguration] = useState<Configuration | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    client.getConfiguration().then(configuration => {
      setConfiguration(configuration);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetConfiguration = async () => {
    try {
      setLoading(true);
      const newConfiguration = await client.setConfiguration({ apiKey });
      setConfiguration(newConfiguration);
      notificationSystem.add?.({
        type: 'toast',
        autoHideDelayTime: 5000,
        payload: {
          variant: 'success',
          content: 'API Key saved succesfully!',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: false,
        },
      });
    } catch (error) {
      let newErrorMessage = 'An error has occurred.';

      if (axios.isAxiosError(error)) {
        newErrorMessage = error.response?.data?.message;
      }

      setErrorMessage(newErrorMessage);

      notificationSystem.add?.({
        type: 'toast',
        autoHideDelayTime: 5000,
        payload: {
          variant: 'error',
          content: newErrorMessage,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: false,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const inputDescription = (
    <>
      Check the
      <DocumentationUrl
        target="_blank"
        href="https://support.atlassian.com/statuspage/docs/create-and-manage-api-keys/"
      >
        documentation
      </DocumentationUrl>
      . This will override your previous configuration.
      <TooltipTarget>
        <Circle onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          i
        </Circle>
        {showTooltip && (
          <CssTooltip zIndex={1000} placement="bottom-end" isVisible={true} width="200px">
            For security reasons we do not show your existing configuration.
          </CssTooltip>
        )}
      </TooltipTarget>
    </>
  );

  const info = (
    <div>
      <InputField
        style={{ width: '100%' }}
        value={apiKey}
        labelText="Status Page API Key"
        placeholder={configuration?.apiKeyExists ? '<configured>' : undefined}
        error={errorMessage}
        id="apiKey"
        type="password"
        description={inputDescription}
        onChange={input => setApiKey(input.target.value)}
      />
    </div>
  );
  const footer = (
    <Button disabled={!apiKey} onClick={handleSetConfiguration} kind="primary">
      Save
    </Button>
  );
  return <SimpleCard loading={loading} title="Configuration" info={info} footer={footer} />;
};

import { Loader, notificationConnect, NotificationProvider, ToastConsumer } from '@livechat/design-system';
import React from 'react';
import { ApiKeyCard } from '../components/ApiKeyCard';
import { ErrorCard } from '../components/ErrorCard';
import { SupportCard } from '../components/SupportCard';
import { useLivechat } from '../hooks/useLivechat';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 2;
`;

export const Admin = () => {
  const { loading, error } = useLivechat({ loginUrl: '/api/admin/livechat' });
  if (error) {
    return <ErrorCard error={error} />;
  }

  if (loading) {
    return (
      <Container>
        <LoaderContainer>
          <Loader size="large" />
        </LoaderContainer>
      </Container>
    );
  }

  const ToastedApiKeyCard = notificationConnect(ApiKeyCard as any);

  return (
    <NotificationProvider itemsLimit={2} queueLimit={1}>
      <Container>
        <ToastConsumer horizontalPosition="center" fixed verticalPosition="top" />
        <ToastedApiKeyCard />
        <SupportCard />
      </Container>
    </NotificationProvider>
  );
};

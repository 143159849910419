import { Card, Divider, Loader } from '@livechat/design-system';
import React, { ReactNode } from 'react';
import { CardContainer } from './CardContainer';
import { Info } from './Info';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  height: 175px;
  display: flex;
  justify-content: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SimpleCard = ({
  info,
  footer,
  title,
  loading,
}: {
  title: string;
  info: ReactNode;
  footer?: ReactNode;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <CardContainer>
        <Card title={title}>
          <LoaderContainer>
            <Loader size="medium" />
          </LoaderContainer>
        </Card>
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <Card style={{ marginTop: '0px' }} title={title}>
        <CardContent>
          <Info>{info}</Info>
          <Spacer />
          {footer && (
            <>
              <Divider />
              {footer}
            </>
          )}
        </CardContent>
      </Card>
    </CardContainer>
  );
};

import axios from 'axios';

export interface Organization {
  licenseId: number;
}

export interface ConfigurationInput {
  apiKey: string;
}

export interface Configuration {
  apiKeyExists: string;
}

export interface Component {
  id: string;
  pageId: string;
  groupId?: string | null;
  createdAt: string;
  updatedAt: string;
  group: boolean;
  name: string;
  description: string;
  position: number;
  status: string;
  showcase: boolean;
  onlyShowIfDegraded: boolean;
  automationEmail: string;
  startDate: string;
}

export const createApiClient = () => {
  const client = axios.create({
    baseURL: '/api',
    withCredentials: true,
  });

  client.interceptors.request.use(config => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  client.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );

  const getOrganization = (): Promise<Organization> => client.get('/organization');

  const setConfiguration = (config: ConfigurationInput): Promise<Configuration> =>
    client.post('/configuration', config);

  const getConfiguration = (): Promise<Configuration> => client.get('/configuration');

  const getComponents = (): Promise<Component[]> =>
    client.get('/components').then((response: any) => response.components);

  return { getComponents, getOrganization, getConfiguration, setConfiguration };
};

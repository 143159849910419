import { Button } from '@livechat/design-system';
import React from 'react';
import { SimpleCard } from './card/SimpleCard';

export const ErrorCard = ({ error }: { error: string }) => {
  const info = (
    <>
      <p>An unexpected error has occured ({error}). You may contact us at </p>
      <a target="_blank" rel="noreferrer" href="mailto:support@flowcorp.xyz">
        support@flowcorp.xyz
      </a>
    </>
  );

  const footer = (
    <Button
      onClick={() => {
        window.open('mailto:support@flowcorp.xyz');
      }}
    >
      Send Email
    </Button>
  );

  return <SimpleCard title="Error" info={info} footer={footer}></SimpleCard>;
};

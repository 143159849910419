import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { Agent } from './pages/Agent';
import { Admin } from './pages/Admin';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/agent" component={Agent} />
      </Switch>
    </Router>
  );
};

import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 300px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  display: flex;

  & > .lc-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 300px;
    margin-top: 0;
  }
`;

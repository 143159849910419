import { createDetailsWidget } from '@livechat/agent-app-sdk';
import { IDetailsWidget } from '@livechat/agent-app-sdk/types/widgets/details';
import { Button, Card, Divider, Loader } from '@livechat/design-system';
import { default as humanizeDuration } from 'humanize-duration';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import styled from 'styled-components';
import { Component } from '../client/client';
import { useClient } from '../hooks/useClient';
import { useLivechat } from '../hooks/useLivechat';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Pair = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
`;

const Key = styled.span`
  font-weight: 700;
  margin-bottom: 0.25rem;
`;

const getLastUpdate = (date: string) => {
  const interval = Interval.fromDateTimes(DateTime.fromISO(date), DateTime.now()).toDuration().valueOf();
  const duration = humanizeDuration(interval, { units: ['d', 'h', 'm'], maxDecimalPoints: 0, language: 'en' });
  return `${duration} ago`;
};

const StatusCard = ({
  component,
  onSendMessage,
}: {
  component: Component;
  onSendMessage: (component: Component) => any;
}) => {
  return (
    <Card title={component.name}>
      <div>
        <p>{component.description}</p>

        <Pair>
          <Key>Status:</Key>
          <span>{component.status}</span>
        </Pair>

        <Pair>
          <Key>Last Update:</Key>
          <span>{getLastUpdate(component.updatedAt)}</span>
        </Pair>

        <Divider />
        <ButtonContainer>
          <Button onClick={() => onSendMessage(component)} kind="primary">
            Prepare Message
          </Button>
        </ButtonContainer>
      </div>
    </Card>
  );
};

export const Agent = () => {
  const client = useClient();
  const { loading: loginLoading, error: loginError } = useLivechat({ loginUrl: '/api/agent/livechat' });
  const [widget, setWidget] = useState<IDetailsWidget | null>(null);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    createDetailsWidget()
      .then(widget => setWidget(widget))
      .catch(error => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: components, error: componentsError } = useAsync({
    promiseFn: client.getComponents,
    watch: counter,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCounter(counter + 1);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter]);

  if (componentsError && (componentsError as any)?.response?.data?.code === 'organization_not_found') {
    return <div>Please complete the integration in the settings page of this app</div>;
  }

  if (!components && componentsError) {
    return <div>Please check app settings</div>;
  }

  if (loginError) {
    return <div>Error</div>;
  }

  if (!widget || !components || loginLoading) {
    return (
      <LoaderContainer>
        <Loader size="medium" />
      </LoaderContainer>
    );
  }

  return (
    <div>
      {components.map(component => (
        <StatusCard
          key={component.id}
          component={component}
          onSendMessage={component => {
            widget.putMessage(
              `Status of ${component.name} is ${component.status?.replaceAll('_', ' ')}. Last update: ${getLastUpdate(
                component.updatedAt
              )}`
            );
          }}
        />
      ))}
    </div>
  );
};
